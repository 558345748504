<template>
    <div class="flex-column " >
        <sticky :className="'sub-navbar'">
            <div class="filter-container borderBotton bgc">
            </div>
        </sticky>
        <div class=" ">
            <el-row>
                <el-col :span="24" >
                    <div class="titdiv" >
                        <h3 class="tith2">  首页标题修改</h3>
                        <div class="datafadiv">
                            <div class="datachdiv"><span class="titspan" :title="title.mainTitle">主标题：</span><el-input size="mini"  v-model="title.mainTitle" class="titinput" clearable placeholder="请输入主标题"></el-input></div>
                            <div class="datachdiv"><span class="titspan" :title="title.viceTitle">副标题：</span><el-input size="mini" v-model="title.viceTitle" class="titinput" clearable placeholder="请输入副标题"></el-input></div>
                            <div class="datachdiv">.
                                <el-button type="danger" class="titbtncan" size="mini" @click="getlist">取消</el-button>
                                <el-button type="primary" class="titbtn" size="mini" @click="subtit">提交</el-button>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
       
    </div>
</template>
<script>
    import * as basicConfiguration from '@/api/basicConfiguration/basicConfiguration'
    import waves from '@/directive/waves' // 水波纹指令
    import Sticky from '@/components/Sticky'
    import elDragDialog from '@/directive/el-dragDialog'
    import extend from "@/extensions/delRows.js"
    export default {
        name: 'wmsGoodsBatchMst',
        components: { Sticky,  },
        mixins: [extend],
        directives: {
            waves,
            elDragDialog
        },
        data() {
            return {
                title:{
                    mainTitle:"",
                    viceTitle:"",
                }
            }
        },
        filters: {
            
        },
        created() {
        },
        mounted() {
            this.getlist()
        },
        activated() {
            
        },
        methods: {
            getlist(){
                basicConfiguration.getTitle().then((res)=>{
                    if(res.code=='200'){
                        if(res.result==null){
                            this.title.mainTitle=""
                            this.title.viceTitle=''
                        }
                        this.title.mainTitle=res.result.mainTitle
                        this.title.viceTitle=res.result.viceTitle
                    }else{
                        this.title.mainTitle=""
                        this.title.viceTitle=''
                    }
                }).catch(()=>{
                    this.title.mainTitle=""
                    this.title.viceTitle=''
                })

            },
            onBtnClicked: function(domId) {
                console.log('you click:' + domId)
                switch (domId) {
                    case 'btnRefresh':
                        this.getlist()
                        break
                    default: 
                        break
                }
            },
            subtit(){
                this.$store.commit("updataListLoading",true)
                basicConfiguration.subtitle(this.title).then((res)=>{
                    if(res.code=="200"){
                        this.$notify({
                            title: "成功",
                            message: res.message,
                            type: "success",
                            duration: 2000,
                        });
                        this.getlist()
                    }else{
                         this.$notify({
                            title: "失败",
                            message: res.message,
                            type: "error",
                            duration: 2000,
                        });
                    }
                    this.$store.commit("updataListLoading",false)
                }).catch(()=>{
                    this.$store.commit("updataListLoading",false)
                })
            }
           
        },
    }
</script>
<style lang="scss" scoped  type="text/scss">
    .bgc{
        background-color: #EFEFEF;
    }
   .titdiv{
       background-color: #fff;
       width: 97%;
       margin:  20px auto 0 auto;
       .tith2{
            padding-top: 20px;
            margin-left:25px
       }
       .datafadiv{
           height: 60px;
           width: 100%;
           display: flex;
           justify-content: space-around;
           .datachdiv{
               height: 33px;
                width: 33%;
                display: flex;
                justify-content: space-around;
               .titinput{
                   
               }
               .titspan{
                   width: 25%;
                   text-align: right;
                   line-height: 33px;
                   overflow: hidden;
                    text-overflow:ellipsis;
                    white-space: nowrap;
               }
               .titbtn{
                   height: 33px;
                   position: relative;
                //    right: 0%;
                   top:25%
               }
               .titbtncan{
                   height: 33px;
                   position: relative;
                   right: -25%;
                    margin-right: 10px;
                   top:25%
               }
           }

       }
   }
</style>
