var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex-column" },
    [
      _c("sticky", { attrs: { className: "sub-navbar" } }, [
        _c("div", { staticClass: "filter-container borderBotton bgc" }),
      ]),
      _c(
        "div",
        { staticClass: "" },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c("div", { staticClass: "titdiv" }, [
                  _c("h3", { staticClass: "tith2" }, [_vm._v(" 首页标题修改")]),
                  _c("div", { staticClass: "datafadiv" }, [
                    _c(
                      "div",
                      { staticClass: "datachdiv" },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "titspan",
                            attrs: { title: _vm.title.mainTitle },
                          },
                          [_vm._v("主标题：")]
                        ),
                        _c("el-input", {
                          staticClass: "titinput",
                          attrs: {
                            size: "mini",
                            clearable: "",
                            placeholder: "请输入主标题",
                          },
                          model: {
                            value: _vm.title.mainTitle,
                            callback: function ($$v) {
                              _vm.$set(_vm.title, "mainTitle", $$v)
                            },
                            expression: "title.mainTitle",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "datachdiv" },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "titspan",
                            attrs: { title: _vm.title.viceTitle },
                          },
                          [_vm._v("副标题：")]
                        ),
                        _c("el-input", {
                          staticClass: "titinput",
                          attrs: {
                            size: "mini",
                            clearable: "",
                            placeholder: "请输入副标题",
                          },
                          model: {
                            value: _vm.title.viceTitle,
                            callback: function ($$v) {
                              _vm.$set(_vm.title, "viceTitle", $$v)
                            },
                            expression: "title.viceTitle",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "datachdiv" },
                      [
                        _vm._v(". "),
                        _c(
                          "el-button",
                          {
                            staticClass: "titbtncan",
                            attrs: { type: "danger", size: "mini" },
                            on: { click: _vm.getlist },
                          },
                          [_vm._v("取消")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticClass: "titbtn",
                            attrs: { type: "primary", size: "mini" },
                            on: { click: _vm.subtit },
                          },
                          [_vm._v("提交")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }